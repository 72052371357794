.TwoInputsBlock {
  display: flex;
  margin-bottom: 24px;
}

.FirstInput {
  width: 360px;
  margin-right: 24px;
}

.OverlapStyle > div > div {
  flex-wrap: nowrap !important;
}

.SecondInput {
  width: 360px;
}

.FirstTextArea {
  width: 50%;
  margin-right: 24px;
}

.SecondTextArea {
  width: 50%;
}

.OneInputBlock {
  display: flex;
  width: 360px;
  margin-bottom: 24px;
}

.AmountBlock {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.StylePrice {
  color: var(--typo-secondary);
}
