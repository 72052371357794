.AccessRightsControls {
  justify-content: flex-end;
  display: flex;
  gap: 12px;
}

.AccessRightButton {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.0333em;
  color: rgb(0 127 57);
  text-decoration: none;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
}
