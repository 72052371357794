.Container div[role='rowgroup'] div {
  text-overflow: initial;
  overflow: hidden;
}

.Button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 12px 0;
}

.Pagination {
  margin-top: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
}
