.CellRoot {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  max-width: 100%;
}


.Icon {
  width: 15px;
  height: 15px;
  background: center center/15px 15px no-repeat;
  position: relative;
}

.Icon[data-border="true"]::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #818C99;
}

.Name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
