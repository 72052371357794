.Table div[role="cell"] > div {
  width: 100%;
}

.Table div[data-name="table__body--row"] {
  overflow: hidden;
}

.Table div[role="columnheader"]:not([colspan="1"]) > div {
  align-self: auto;
}
