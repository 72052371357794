.Container {
  display: inline-flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Icon {
  margin-right: 8px;
  display: inline;
  width: 18px;
  height: 18px;
  z-index: 1;
  background-color: inherit;
}

.CircleIcon {
  position: relative;
}

.CircleIcon::before {
  position: absolute;
  content: '';
  left: 2px;
  top: 2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #818c99;
}

.Text {
  z-index: 1;
  bottom: 2px;
  position: relative;
}
