/* stylelint-disable custom-property-pattern */

.Icon {
  /* cash */
  --R-stop-color-1: #e2514e;
  --R-stop-color-2: #ff706e;
  --R-border-color: #ea3431;

  /* forex */
  --F-stop-color-1: #ff9925;
  --F-stop-color-2: #ffb649;
  --F-border-color: #f47313;

  /* future */
  --I-stop-color-1: #ffe169;
  --I-stop-color-2: #ffff79;
  --I-border-color: #f5bc39;

  /* option */
  --C-stop-color-1: #64bb6a;
  --C-stop-color-2: #83da89;
  --C-border-color: #3d9d4a;

  /* option CALL */
  --CALL-stop-color-1: #64bb6a;
  --CALL-stop-color-2: #83da89;
  --CALL-border-color: #3d9d4a;

  /* option PUT */
  --PUT-stop-color-1: #64bb6a;
  --PUT-stop-color-2: #83da89;
  --PUT-border-color: #3d9d4a;

  /* bond */
  --B-stop-color-1: #67adeb;
  --B-stop-color-2: #9ad6fe;
  --B-border-color: #4f79b2;

  /* stock */
  --S-stop-color-1: #1972a8;
  --S-stop-color-2: #3a91c7;
  --S-border-color: #305c99;

  /** fund */
  --O-stop-color-1: #a14dbd;
  --O-stop-color-2: #c672e2;
  --O-border-color: #743089;

  /** CFD */
  --CFD-stop-color-1: #1e1e1e;
  --CFD-stop-color-2: black;
  --CFD-border-color: black;

  /** crypto */
  --CRYPTO-stop-color-1: var(--R-border-color);
  --CRYPTO-stop-color-2: var(--F-border-color);
  --CRYPTO-border-color: #eb413f;

  /** structured */
  --STRUCTURED-stop-color-1: #a41a46;
  --STRUCTURED-stop-color-2: #ee1772;
  --STRUCTURED-border-color: #a41a46;

  /** other, inactive, unknown */
  --OTHER-icon-bg-color: #c3c3c3;

  width: 10px;
  min-width: 10px;
  height: 10px;
  margin-right: 7px;
  vertical-align: baseline;
}

.Icon:focus {
  outline: none;
}


.Circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--instrument-icon-border-color);
  background-image: linear-gradient(
    to right,
    var(--instrument-icon-stop-color-1),
    var(--instrument-icon-stop-color-2)
  );
}

.CRYPTO {
  background-image: linear-gradient(
    to bottom right,
    var(--instrument-icon-stop-color-1),
    var(--instrument-icon-stop-color-1) 49%,
    var(--instrument-icon-stop-color-2) 51%
  );
}

.OTHER,
.Circle {
  background-color: var(--OTHER-icon-bg-color);
}

