.PanelHeaderControls {
  display: grid;
  grid-auto-flow: column;
  gap: 12px;
  align-items: center;
  width: 100%;
  justify-content: end;
}

.RefreshButton {
  justify-self: end;
}
