.Wrapper {
  background: white;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}

@media (min-width: 1200px) {
  .Wrapper {
    flex-wrap: nowrap;
  }
}

.Wrapper > div {
  display: flex;
  flex-wrap: wrap;
  width: 280px;
}
