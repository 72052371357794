.GroupSettings {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.HelperText {
  margin: 6px 0 0 13px;
  font-size: 14px;
  color: gray;
  display: flex;
}

.HelperText > span {
  margin-right: 5px;
}

