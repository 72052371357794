.Wrapper {
  position: relative;
}

.Search div,
.Search input {
  width: 100%;
}

.PanelHeader {
  flex-wrap: wrap;
}

.PanelHeader > div:last-child {
  align-items: center;
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .PanelHeader > div:last-child {
    margin-left: 0;
  }
}

.PanelGroup {
  grid-auto-columns: minmax(0, 1fr);
}

.Table {
  margin-top: 30px;
}

.Table div[class$="table-cell"] ~ div[class$="table-cell"],
.Table div[role="columnheader"] ~ div[role="columnheader"] {
  flex: 1 0 auto !important;
}

/* fix for horizontal scroll on Windows arising from virtualization */
.Table > div:last-child {
  overflow-x: hidden;
}

/* fix for vertical scroll from virtualization */
.Table > div:last-child > div {
  min-width: auto;
}

.Table div[role="rowgroup"] > div,
.Table div[role="row"]:not([data-name="table__body--row"]) {
  overflow-y: scroll !important;
}
