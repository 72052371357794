.Title.Title {
  padding: 24px 0;
}

.GroupWrapper h2 {
  text-transform: capitalize;
  margin: 0;
}

.GroupWrapper > div {
  border-bottom: 1px solid var(--bg-basic);
}

.GroupWrapper > div:nth-child(-n + 2) {
  border-top: 1px solid var(--bg-basic);
}
