.Container {
  display: flex;
}

.Button {
  position: relative;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.Button svg {
  display: block;
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.SearchWrapper {
  margin-bottom: 12px;
}

.ListWrapper {
  position: absolute;
  z-index: 1;
  top: 24px;
  right: 0;
  background-color: white;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 550px;
  min-width: 210px;
  padding: 16px;
}

.List {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.Item {
  display: flex;
  text-transform: capitalize;
  color: var(--typo-ghost);
}

.ResetButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
