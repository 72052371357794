.Container {
  display: grid;
  grid-row-gap: 16px;
}

.Item {
  display: grid;
  grid-template-columns: 320px 320px 200px 1fr;
  grid-column-gap: 16px;
}

.AddWrapper {
  display: flex;
  justify-content: flex-end;
}

.MinusWrapper {
  display: flex;
  justify-content: center;
  width: 32px;
  margin-left: auto;
}
