.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 24px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchContainer {
  width: 100%;
  margin: 24px 0;
  position: relative;
}

.SearchContainer div,
.SearchContainer input {
  width: 100%;
}

.Title {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 32px;
  font-family: 'Fox Sans Pro', sans-serif;
}

.Buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}
