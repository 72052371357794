.Panel h2 {
  margin: 0;
}

.Content {
  padding-top: 16px;
}

.Controls {
  padding-right: 32px;
  margin-bottom: 16px;
}
