.Value {
  background-color: transparent;
}

.Positive {
  color: var(--typo-source);
}

.Negative {
  color: var(--typo-radical);
}

.Expired {
  color: var(--typo-ghost);
}
