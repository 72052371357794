.GroupSettings {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.HelperText {
  margin: 6px 0 0 13px;
  font-size: 14px;
  color: gray;
}

.TextLink {
  color: var(--typo-action);
}

.TextLink:hover {
  color: var(--typo-promo);
}
