.Button {
  width: 12px;
  height: 22px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.Button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
