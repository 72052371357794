.Wrapper {
  gap: 20px;
  background: white;
  display: flex;
  justify-content: flex-start;
}

.Skeleton {
  transform: none;
  width: 280px;
}
