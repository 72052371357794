.Item {
  line-height: 1.2;
}

.Item dt {
  display: inline-block;
  font-weight: bold;
}

.Item dt::after {
  content: ':\00a0';
}

.Item dd {
  display: inline;
  margin-left: 0;
}

.Item ul {
  padding-left: 10px;
}
