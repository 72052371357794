.Cell {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 20px;
}

.Icon {
  width: 20px;
  height: 20px;
}

.FallbackIcon {
  background-color: rgb(239 68 68);
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.Text {
  display: inline-block;
  margin-left: 8px;
  line-height: 0;
}
