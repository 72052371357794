.Wrapper {
  display: flex;
  align-items: center;
}

.WarningIcon {
  display: inline-flex;
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: var(--typo-radical);
  margin-right: 8px;
}

.ItemWrapper {
  margin-right: 8px;
}
