.Wrapper {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: var(--typo-primary);
  overflow: hidden;
  max-width: 600px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Marked {
  background-color: transparent;
  color: var(--typo-action);
}
