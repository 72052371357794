.Container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Item {
  display: flex;
  column-gap: 16px;
}

.AddWrapper {
  display: flex;
  justify-content: flex-end;
}

.DeleteWrapper {
  display: flex;
  justify-content: center;
  width: 32px;
}
