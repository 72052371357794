.FormItem {
  display: flex;
  gap: 16px;
}

.FormItemColumn {
  flex-direction: column;
}

.FormItemRow {
  flex-direction: row;
}

.FormItemMargin {
  margin-bottom: 16px;
}

.FormItemPadding {
  padding: 0 24px;
}
