.Wrapper {
  display: flex;
  align-items: center;
  margin: 0;
}

.Label {
  display: inline-flex;
  align-items: center;
}

.DottedLine {
  border: none;
  border-top: 1px dashed var(--line-secondary);
  color: var(--typo-ghost);
  height: 1px;
  flex-grow: 1;
  align-self: self-end;
  margin: 0;
  margin-left: 4px;
  margin-right: 4px;
}

.Value {
  margin: 0;
}

.Legend {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}
