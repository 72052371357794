.FullIcon {
  position: relative;
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.NoIconUrl {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  font-size: 11px;
  color: #fff;
}

.NoIconUrl.TextBlack {
  color: #000;
}

.NoIconUrl.CFD {
  color: #fff;
}

.NoIconUrl.CurrencySelect {
  width: 19px;
  line-height: 19px;
  font-size: 5px;
}

.InstrumentIcon {
  position: absolute;
  height: inherit;
  width: inherit;
  background-size: cover;
  border-width: 2px;
  padding: 1px;
  background-clip: content-box;
  box-sizing: border-box;
  top: 0;
  left: 0;
}
