.TransactionImport input[type="file"] + span {
  text-transform: none;
}

.TransactionImportTable {
  margin-top: 30px;
}

.ActionsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}
