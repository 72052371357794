.Header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #409f6b;
  position: relative;
  padding: 0 24px;
  height: 56px;
}

.Title {
  display: inline-block;
  font-size: 22px;
  color: white;
}

.CurrencySelectorContainer {
  margin-right: auto;
  margin-left: 25px;
}
