.Wrapper {
  margin-top: 1px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.PanelGroup {
  display: block !important;
}

.Panel {
  display: grid;
  grid-row-gap: 30px;
}

.Panel + .Panel {
  margin-top: 8px;
}

.InfoIcon {
  color: #818C99;
}
