.Wrapper {
  margin-top: 1px;
}

.WrapperSwitchModeIsTrue {
  grid-template-columns: 1fr 1fr;
}

.WrapperSwitchModeIsFalse {
  grid-template-columns: 1fr;
}

.Column {
  display: grid;
  grid-row-gap: 30px;
}

.Commission {
  display: flex;
  align-items: flex-end;
}

.Commission span {
  font-size: 15px;
  line-height: 16px;
  color: #4e5d60;
}

.FormItem {
  grid-template-columns: 262px 262px;
  display: grid;
}

.Hidden {
  opacity: 0;
  visibility: hidden;
}
