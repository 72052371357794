.Item {
  display: grid;
  grid-template-columns: 320px 320px 200px 1fr;
  grid-column-gap: 16px;
}

.Title {
  font-size: 18px;
  color: rgb(37 44 46);
  overflow: hidden;
  text-overflow: ellipsis;
}

.Item + .Item {
  margin-top: 12px;
}

.RemoveButtonContainer {
  display: flex;
  align-items: center;
}
